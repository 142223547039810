<template>
  <div>
    <router-view></router-view>
    <nav-footer></nav-footer>
  </div>

</template>

<script>
import NavFooter from '../components/Footer'
export default {
  name: "Home",
  components:{
    NavFooter
  }
}
</script>

<style scoped>

</style>
