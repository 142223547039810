export default {
    header:{
      aHome:'SOHO中国',
      arent:'商办租售',
      anews:'新闻媒体',
      invest:'投资者关系',
      pucharset:'采购',
      tenement:'物业',
      commune:'长城脚下的公社',
      esg:"ESG"
    },
    route:{
        menu:"目录",
        env:'环境',
        socity:'社会',
        gov:'管治',
        media:'媒体',
        cert:'报告',
        esgCert:'ESG报告',
        menureport:'报告',
        TCFD:'气候行动报告',
        activity:'社区活动',
        development:'可持续采购',
        employee:'员工关爱',
        Client:'客户服务',
        Charity:"公益慈善",
        downesg:'下载esg报告',
        elecsave:'2023年节能率',
        consumption:'2023年减碳率',
        watter:'累计节能率',
        envcontent:'践行绿色环保的环境责任，围绕建筑开发和运营等多角度，优化能源使用效率，减少污染物排放，打造绿色可持续发展建筑。',
        societycontent:'积极承担企业社会责任。坚持以人为本的理念，不断优化人力资源管理体系和制度保障。依托高科技平台化管理，持续提高客户服务质量。注重回馈社会，在教育、扶贫、救灾等方面贡献公益慈善的力量。',
        tstitle:'2021年11月驰援甘肃天水抗疫',
        tsnum:'100,000,000',
        subsidized:'2020年为租户提供疫情补贴',
        Kindergarten:'2021年7月驰援河南抗洪救灾',
        govcontent:'SOHO中国致力于与合作伙伴团结协作，从环境保护、社会责任、企业管治三方面共同推动ESG管理工作。2021年 11月，SOHO中国在董事会设立ESG委员会，負責就ESG事宜向董事会汇报，并监察本公司环境、社会及管治愿景、策略、目标及政策的制定和实施。ESG委员会直接领导ESG工作组，完善内部管理机制，明确监管责任和工作目标，将可持续发展融入本公司的日常管理中，为ESG工作落地提供指导性办法，严格规范和有效管理ESG各项事务。',
        unit:'元',
        downloadesg:'下载ESG报告',
        envtitle:'环境',
        socitytitle:'社会',
        govtitle:'管治',
        policy:'管治架构及政策',
        risk:'风险管理'
    },

    env:{
        achievement:'绿色成果',
        achnum:'1.2',
        yidu:'亿度',
        enery:"2021年节能",
        achnum1:'10',
        achnumContent:'万吨',
        reduction:'2021年减碳',
        equivalent:'相当于',
        equivanum:'550',
        equivaContent:'万棵树',
        tree1:'一年的CO',
        tree2:'净吸收量',
        target:'绿色目标',
        targetYear:'2021年至2025年',
        targetnum:'6',
        tarnumdetail:'万吨+',
        targetdetail:'每年减碳',
        targetnum1:'30',
        tarnumdetail1:'万吨+',
        targetdetail1:'5年累计减碳',
        carbontitle:'零碳先锋',
        carbonContent:'为了践行国家的“双碳”战略目标，2021年SOHO中国启动“碳中和”创新标杆项目，打造中国第一个“零碳图书馆”示范楼。该图书馆采用最高建筑能效标准设计及建造，运用多项国际领先的建筑技术，如被动式门窗及保温、光伏发电、高效空调、新风热回收、雨水收集、建筑智能系统等，力争成为中国零碳建设的先锋。',
        //项目总耗能
        enerytitle:'能源突破联盟',
        enerycontent:'2016年，SOHO中国加入由比尔·盖茨发起、全球28名顶尖互联网公司及投资集团的商界领袖共同成立的“能源突破联盟”，致力于改善电力能源的生产和储存，从而实现碳零排放。',
        projecttitle:'项目总能耗',
        projecttime:'2021年截至6月',
        projectsmall:'个项目',
        pnum:'13559',
        pnum2:'万度',
        pleft:'对比国标累计节约',
        pleftnum:'5052',
        pcenter:'节能率',
        pcenternum:'27.1',
        pright:'相当于减碳',
        prightnum:'4.2',
        prightdetail:'万吨',
        //绿色开发
        greendev:'绿色开发',
        greencontent:'从2009年起，SOHO中国开发的所有项目均采用美国绿色建筑LEED标准设计和建造。截至目前共有8个项目获得LEED金级认证，1个项目获得LEED银级认证。',
        devnum:'218',
        devnumdetail:'万平方米',
        devnumcontent:'LEED认证面积',
        devnum1:'44%',
        devnumcontent1:'占开发总面积',

        //绿色运营
        opentitle:'绿色运营',
        opencontent:'2013年SOHO中国建立节能中心，同年搭建能源管理平台，通过能源的监控分析、节能改造以及系列管理制度，达到绿色运营，降低能源消耗。',
        opendetail:'SOHO中国所有项目已实现',
        opendetail1:'平台化',

        //绿色荣耀
        honourtitle:'绿色殊荣',
        honourhead:'SOHO中国在节能减排方面屡次获得政府认可及嘉奖，',
        honourtext:'共获得奖金:',
        honournum:'1400',
        honourunit:'万元',
        honourcontenttop1:'望京SOHO和光华路SOHO2获LEED金级认证，获“北京市朝阳区节能发展引导资金”452万元。',
        honourcontentbottom1:'',
        honourcontenttop2:'光华路SOHO2冰蓄冷集中空调及冷站，获“北京市电力需求侧管理城市综合试点工作奖励奖金”42万元。',
        honourcontentbottom2:'',
        honourcontenttop3:'能源管理平台及LED照明节能改造项目，获得”北京市朝阳区节能发展引导资金”奖金359万元。',
        honourcontentbottom3:'',
        honourcontenttop4:'节能碳减排实现碳配额转让1.4万余吨，碳交易收益55万元。',
        honourcontentbottom4:'',
        honourcontenttop5:'建外SOHO A、B座和SOHO嘉盛中心，获得“北京市公共建筑节能绿色化改造奖励资金”493万元。',
        honourcontentbottom5:'',
        honourcontenttop6:'银河SOHO、望京SOHO T3、光华路SOHO2、SOHO天山广场，获得“二星级绿色建筑标识证书”。',


    },

    foundation:{
        title:'SOHO中国一直坚持回馈社会，在扶贫、救灾、教育等多方面持续贡献企业力量。2005年成立SOHO中国基金会。',
        nav1:'养正幼儿园',
        nav2:'抗疫救灾',
        nav3:'西部助学',
        nav4:'SOHO中国助学金',
        nav5:'助力环境保护',
        nav6:'一家人餐厅',
        nav7:'特奥融合学校',
        nav8:"美德教育",
        nav9:"零碳图书馆",
        carbonnum:'2000',
        carbontitle:'零碳图书馆',
        carboncontent:'为了助力“乡村振兴”、丰富乡村居民的精神文化生活，2021年SOHO中国基金会投入2000万元在天水市麦积区石咀村建设“零碳图书馆”，为周边居民提供文化休闲场所，同时为当地的幼儿园、中小学及大专院校等师生服务。该图书馆于2022年12月底竣工。',
        carbondtag:'捐建公益图书馆',
        kindergartentitle:'养正幼儿园',
        kindergartencontent:'在甘肃天水麦积区马跑泉镇石咀村，投资3000万元捐建非盈利性项目“养正幼儿园”。2020年1月竣工，10月开园，由北京家育苑教育管理集团运营，可接纳180名幼儿就读。每年还将持续投入约300万元。',
        kindergartennum:'3000',
        kindrengartenunit:'万',
        kindergartendetail:'捐建非盈利性幼儿园',
        disastertitle:'抗疫救灾',
        disasternum:'1.66',
        disasterunit:'亿元',
        disasterdetail:'疫情补贴',
        disastercontent:'2020年SOHO中国为租户提供疫情补贴，与租户共克时艰。',
        disasternum1:'20',
        disasterunit1:'万元',
        disasterdetail1:'天水花牛苹果',
        disastercontent1:'通过中国红十字会向武汉七家医院捐赠天水花牛苹果',
        disasternum2:'12',
        disasterunit2:'万元',
        disasterdetail2:'核酸检测试剂',
        disastercontent2:'向湖北省黄石市疾控中心捐赠核酸检测试剂。',
        disastercontent22:'向北京协和医院武汉援助队捐赠N95口罩、负压面罩等医疗设备。',
        //西部助学
        westtitle:'西部助学',
        westnum:'4000',
        westunit:'万元',
        westcontentp:'历年来累计向甘肃天水麦积区潘集寨学校捐赠约4000万元，用于建设教学楼、操场、采购校服教具、支持教师培训等项目。',
        westcontentbottom:'支持天水启升中学公益摄影班、美德教育工程、改造天水地区学校厕所。',
        westnumright:'1000',
        westunitright:'万元',
        westcontentright:'2007年，为改善青海黄南藏族自治州的教育发展捐赠1000万元，是当时青海历史上最大的一笔善款捐助。',


        //soho中国助学金
        studenttitle:'SOHO中国助学金',
        studentcontent:'2014年启动SOHO中国助学金项目，资助中国本科学生到世界一流大学深造。目前已与哈佛大学、耶鲁大学、芝加哥大学签署捐赠协议，截至2020年共有222人次中国本科留学生受益。',
        studentnum:'222',
        studentunit:'人次',
        studentDetail:'中国本科留学生受益',

        //能源突破
        enerytitle:'能源突破联盟',
        enerycontent:'2016年，SOHO中国加入由比尔·盖茨发起、全球28名顶尖互联网公司及投资集团的商界领袖共同成立的“能源突破联盟”，致力于改善电力能源的生产和储存，从而实现碳零排放。',
        //一家人
        familytilte:'一家人餐厅',
        familycontent:'2020年投资建立“一家人餐厅”，为甘肃天水麦积区马跑泉镇石咀村80岁以上老人提供免费午餐。2020年11月餐厅正式营业，截至2021年6月共有31名老人受益。',

        //学校
        scholltitle:'特奥融合学校',
        schollcontent:'与上海特殊关爱基金会继续开展公益项目，以服务残障人士、改善他们的身体、心理和精神状况为目标。截至2020年底，累计捐赠650万元。',

        //美德培训
        virtuetitle:'儿童美德教育',
        content1:'美德培训 在2008年至2012年间，培训了甘肃天水的1,700多名教师和250多位校长，为61,000名儿童提供美德课程。同时针对天水地区卫生间设施落后的情况，捐建了45间厕所，共有35,000名孩子受益。',
        content2:'儿童美德项目旨在唤起儿童对个人和社区的责任感，并激发他们追求卓越的动力。从幼儿园到三年级的孩子从事人物塑造练习，强调不同的美德，如礼貌，责任，真实和乐于助人。儿童美德计划是专门针对早期教育而设计的，并考虑了不同年龄的儿童如何通过歌曲，游戏和互动活动组成的课程来学习美德。',
        virtuenum:'61,000',
        virtuedetail:'名孩子受益',
        envtitle:'助力环境保护',
        envcontent:'为提升农村地区卫生条件、改善农村人居环境，2021年12月SOHO中国基金会成立专项小组助力天水麦积区石咀村环境保护，共计投入40余万元，为村民捐赠一台环卫车，施工改造8个垃圾站点，安装54个垃圾分类桶，倡导村民实施垃圾分类。',

        //百草园
        herbaltitle:'百草园',
        herbalcontent:'2022年，SOHO中国基金会投入400万元，在甘肃省天水市麦积区马跑泉镇石咀村建设了一个名为“百草园”的公益项目。该项目旨在为养正幼儿园的孩子们和老师们提供自然教学和教研场所。百草园中的智慧种植棚采用高科技无土种植技术，在室内无需土壤的情况下可以种植各种蔬菜、水果和其它植物。系统自动浇水、施肥，并监测室内温度、湿度和光照情况。自动控制补光灯和遮阳帘的开启和关闭，使植物获得充足的阳光，提供所需的温度和湿度环境，让植物能够全年生长。“百草园”的科技种植让孩子们体验到了一年四季都能收获的快乐，开启了他们对现代农业发展的探索之旅。'
    },
    clients:{
        bannertext:'免费开放5G实验室',
        bannertext1:'集成8大类5G创新应用场景',
        bannertext2:' 截至2021年3月实地参观',
        visitnum:'4755',
        visitunit:'人次',
        visitnum1:'316',
        visitunit1:'家公司',

        //服务
        servicetitle:'持续提升物业服务水平',
        servicetitle1:'建立综合全面、快速响应的物业服务体系',
        //第一个li
        serviceLititle:'客户服务工单化',
        serviceLinum:'15',
        servicetop:'分钟',
        servicebottom:'上门',
        serviceLinum1:'15',
        servicetop1:'工作日',
        servicebottom1:'处理完成',
        //第二个li
        serviceLititle2:'物业清洁智能化',
        serviceLinum2:'42',
        servicetop2:'台智能清洁机器',
        servicebottom2:'人全天候工作',
        //第三个li
        servicecontent:'引入三大电信运营商及多家二级运营商，每月对手机及网络信号排名，保障高速的互联网信号',

        //第四个li
        serviecontent1: '建立外委服务检查体系',
        serviecontent2: '以及物业品质检查体系',

        //体系
        systemtitle:'构建客户信息安全体系',
        systemcontent:'重视信息及网络安全，不断完善管理制度，多措并举，努力维护客户个人信息及隐私安全。目前已建立安全技术、安全管理和安全核查三位一体的信息安全体系。',

        //意见
        handlertitle:'客户满意度调查及投诉处理',
        handlercontent:'建立呼叫中心，快速、直接、独立地收集问询、报修、建议和投诉，多渠道及时处理解决问题。持续开展满意度调查，2023年在租租户满意度99.81分，聘请第三方公司，以神秘客暗访的形式突击检查，2023年神秘访客满意度96.27分，同时开展年度客户净推荐值专项调查，2023年客户净推荐值（NPS）79.16分。',
        handlerdetail:'2023年在租租户满意度'

    },
    hr:{
        hrtitle:'合规、平等及多元化雇佣',
        hrtitle1:'权益保护',
        hrcontent:'坚持依法、平等、多元的雇佣原则，健全人力资源管理体系和制度保障，重视员工民主管理，切实提高员工权益。',
        hrcontact:'正式劳动合同签订率',
        hryear:'年共有员工',
        people:'人',
        age:'30及以下',
        age2:'30-39岁',
        age3:'40岁及以上',
        //平台
        platform:'培训与发展',
        platformcontent:'打造线上知识管理平台，实现线上与线下培训相结合，构建职业培训课程体系和内部培训师体系的“双支柱”人才培养模式。',
        platformtitle:'注重员工的发展与培训',
        platformtopunit:'场次',
        platformtopdetail:'2023年共完成专项培训',
        platformcenterunit:'小时',
        platfromcenterdetail:'累计培训时长',
        platformbottomunit:'人次',
        platformbottomdetail:'累计培训',

        //banenr
        hrbannercontent:'定期举办员工运动会、生日会、家庭日活动等，',
        hrbannercontent2:'营造“诚实、团结、创新”的工作氛围和企业文化。',
        degreetitle:'提升员工满意度',
        degreecontent:'2023年，本公司自行开展员工满意度调查，包括净推荐值（NPS）、职业健康与安全、商业道德等方面，多维度制定详尽的员工满意度调查方案。',
        degreecontent1:'我们共发出问卷1,708份，收到有效反馈1,622份，员工参与率达到95%，员工满意度整体得分为4.6分，与2022年持平，保持在较高水平。',
        degreecontentright:'员工满意度（满分5分）',
        degreecontentrighttext:'分',
        noticeHelth:'注重健康安全',
        noticeContent:'把员工的职业健康安全放在首位。我们相信，安全来自预防，事故源于麻痹。2023年无任何因公身亡安全事故。'
    },
    control:{
        controltitle:'ESG管治',
        controltitle1:'ESG管治架构',
        connecttitle:'ESG管治',
        connectcontent2:'SOHO中国致力于与合作伙伴团结协作，从环境保护、社会责任、企业管治三方面共同推动ESG管理工作。2021年 11月，SOHO中国在董事会设立ESG委员会，負責就ESG事宜向董事会汇报，并监察本公司环境、社会及管治愿景、策略、目标及政策的制定和实施。ESG委员会直接领导ESG工作组，完善内部管理机制，明确监管责任和工作目标，将可持续发展融入本公司的日常管理中，为ESG工作落地提供指导性办法，严格规范和有效管理ESG各项事务。',
        esgpolicy:'ESG政策',
        risk:'ESG风险管理'
    },

    media:{
        videotitle:'视频新闻',
        newstitle:'图文新闻',
    },
    activity: {
        title:"社区活动",
    },
    development:{
        title:"可持续发展",
    }


}
