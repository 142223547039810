<template>
 <div class="test">
   esg测试文件
 </div>
</template>

<script>
export default {
  name: "test"
}
</script>

<style scoped>
 test{
   background: red;
 }
</style>