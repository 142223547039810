<template>
  <div id="foot" >

    <div class="footer-container pubCon" v-if="this.$i18n.locale=='zh'">
      <!-- <div>
        <a href="https://classic.sohochina.com/about">公司</a>
        <a  style="margin-left: 20px" href="https://classic.sohochina.com/careers">加入我们</a>
      </div> -->
      <div class="contacts">
        联系我们
      </div>
      <div class="conInfo clearfix" >
        <div>
          <span>北京总部：</span>
          <span>SOHO中国有限公司</span>
          <span>北京市朝阳区</span>
          <span>朝外大街乙6号</span>
          <span>朝外SOHO A区11层</span>
          <span>邮编：100020</span>
        </div>
        <div >
          <span>上海分公司：</span>
          <span>上海市黄浦区</span>
          <span>中山东二路88号</span>
          <span>外滩SOHO C栋32层</span>
          <span>邮编：200040</span>
        </div>
        <div>
          <span>咨询电话: 400-815-9888</span>
          <span>传真: (86 10) 6567-1064</span>
          <span>Email:  sohohotline@sohochina.com</span>
        </div>
      </div>

      <div class="copyright">
        <span>© 2012-2020 SOHO中国 版权所有。</span>
        <span>京ICP备05014360号</span>
        <span>京公网安备 11010502030562号</span>
        <span>北京红石建外房地产开发有限公司 版权所有</span>
      </div>
    </div>
    <div class="footer-container pubCon" v-if="this.$i18n.locale=='en'">

      <div class="contacts">
        Contact Us
      </div>
      <div class="conInfo clearfix" >
        <div>
          <span>SOHO China Ltd.</span>
          <span>Chaowai SOHO, Building A, Floor 11</span>
          <span>6B Chaowai Street, Chaoyang District
          </span>
          <span>Beijing 100020, China</span>
        </div>
        <div>
          <span> Tel: 400-815-9888</span>
          <span>Fax: (86 10)  6567-1064</span>
          <span>Email:  sohohotline@sohochina.com</span>
        </div>
      </div>

      <div class="copyright">
        <span>© 2012-2020 SOHO China. All Rights Reserved.</span>
        <span>京ICP备05014360号</span>
        <span>京公网安备 11010502030562号</span>
        <span>北京红石建外房地产开发有限公司 版权所有</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style  lang="scss">
.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}
#foot{

  box-sizing: border-box;
  background-color: #f1f1f1;
  width: 100%;
  padding: 50px 0 80px 30px;
}


#foot a{
  font-size: 14px;
  color: rgb(102, 102, 102);

}

.conInfo {
  margin-top: 10px;
  display: flex;
  width: 100%;

}

.conInfo div{
  margin-left: 50px;
}



.contacts{
  color: rgb(102, 102, 102);
  font-size: 16px;
  line-height: 20px;
  // font-weight: bold;
  display: block;
  // border-top: solid 1px #CBCBCB;
  // padding-top: 43px;
  // margin-top: 50px;


}
.conInfo div:nth-child(1){
  margin-left: 0;
}
.conInfo span{
  color: rgb(102, 102, 102);
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  display: block;
  vertical-align: top;

}

.copyright{
  padding-top: 50px;
  font-size: 12px;
}

.copyright span{
  color: rgb(102, 102, 102);
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  display: block;
  vertical-align: top;

}
.footer-container{
  width: 1200px;
  margin: 0 auto;
}
@media(max-width:992px) {
  #foot{
    padding: 60px 0;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
  .footer-container{
    width: 80%;
    margin: 0 auto;
  }

  .conInfo{
    flex-wrap: wrap;
  }

  .conInfo div {
    width: 50%;
    margin-left: 0px;
  }

  .contacts{
    padding-top: 30px;
  }
  .copyright{
    padding-top: 30px;
  }



}


</style>
