import request from '../utils/request'

export function getdataInfo(params) {
   return request({
       url:'/esg/generator/esgcontent/info',
       method:'post' ,
       params

   })
}
