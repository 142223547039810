export default {
    header:{
        aHome:'SOHO China',
        arent:'Leasing & Sale',
        anews:'News & Media',
        invest:'Investor Relations',
        pucharset:'采购',
        tenement:'物业',
        commune:'Commune',
        esg:"ESG"
    },
    route:{
        menu:'Menu',
        env:'Environmental',
        socity:'Social',
        gov:'Governance',
        media:'Media',
        employee:'Employee Care',
        Client:'Client Services',
        activity:'Community Activities',
        development:'Sustainable Purchasing',
        Charity:"Public Welfare",
        elecsave:'2023 Energy Saving Rate',
        consumption:'2023 CO2 Reduction Rate ',
        watter:'Cumulative Energy saving Rate',
        envtitle:'ENVIRONMENTAL',
        socitytitle:'SOCIAL',
        govtitle:'GOVERNANCE',
        envcontent:'Fulfilling environmental protection, focusing on aspects like building development and operation , optimizing energy consumption, reducing pollutant emissions, and creating sustainable buildings.',
        societycontent:'Actively shouldering CSR, adhering to people-oriented management, constantly optimizing HR management system; closely relying on high-tech platform management and consistently improving customer service; focusing on social contribution, and contributing to public charity in education, poverty alleviation, and disaster relief.',
        subsidized:'Subsidized to clients in 2020',
        Kindergarten:'Henan Flood in 2021 July',
        govcontent:'SOHO China is committed to cooperating with partners to jointly drive ESG management from the three aspects of environmental protection, social responsibility and corporate governance. In November 2021, SOHO China established an ESG Governance Committee responsible for reporting to the board of directors in terms of ESG matters, and overseeing the establishment and implementation of the vision, strategies, goals, and policies of the company\'s environmental, social and corporate governance. The ESG committee directly leads the ESG working group, improves internal management mechanisms, clarifies supervisory responsibilities and work objectives, integrates sustainable development into the company\'s daily management, provides guiding methods for the implementation of ESG work, and strictly regulates and effectively manages ESG affairs .',
        unit:'RMB',
        tstitle:'Combat the epidemic in Tianshui 2021 November',
        cert:"Report",
        downloadesg:'Download ESG REPORT',
        esgCert:'ESG Report',
        menureport:'Report',
        TCFD:'Climate Action Report',
        policy:'Structure and policy',
        risk:'Risk management'
    },
    env:{
        achievement:'GREEN ACHIEVEMENTS',
        achnum:'120',
        yidu:'Million kWh',
        enery:"2021 Energy saving",
        achnum1:'100K',
        achnumContent:'tonnes',
        equivanum:'5500K',
        equivaContent:'trees',
        reduction :'2021 Carbon emission reduction',
        equivalent:'Equivalence of',
        tree1:'The annual net CO',
        tree2:'absorption',
        target:'GREEN TARGETS',
        targetYear:'2021-2025',
        targetnum:'60K',
        tarnumdetail:'tonnes',
        targetdetail:'Annual carbon reduction',
        targetnum1:'300K',
        tarnumdetail1:'tonnes',
        targetdetail1:'Carbon reduction in 5 yr.',
        carbontitle:'ZERO CARBON PIONEER',
        carbonContent:'Fully committed to implementing the country\'s "Carbon Peaking and Carbon Neutrality" strategic goal, SOHO China has launched the "carbon neutrality" innovation benchmarking project in 2021 to build China\'s first "zero carbon library" demonstration building. The library is designed and constructed in accordance with the highest building energy efficiency standards, and uses a number of internationally leading building technologies, including passive doors and windows, thermal insulation, photovoltaic power generation, high-efficiency air conditioning, fresh air heat recovery, rainwater collection, and building intelligent systems. It strives to become the pioneer in China\'s zero-carbon construction.',
        enerytitle:'BREAKTHROUGH ENERGY COALITION',
        enerycontent:'In 2016, SOHO China joined Breakthrough Energy Coalition established by Bill Gates and business leaders from 28 top internet companies and investment groups. Breakthrough Energy commits to improving the production and storage of electric energy that will lead the world to net-zero emissions.',
        //项目总耗能

        projecttitle:'TOTAL PROJECT ENERGY CONSUMPTION',
        projecttime:'AS OF JUNE 2021',
        projectsmall:'Projects',
        pnum:'135.59',
        pnum2:'Million kWh',
        pleft:'Cumulative saving (cf. with GB)',
        pleftnum:'50.52',
        pcenter:'Energy Saving Rate',
        pcenternum:'27.1',
        pright:'EQUIV to carbon reduction of',
        prightnum:'42k',
        prightdetail:'Million kWh',
        //绿色开发
        greendev:'GREEN DEVELOPMENT',
        greencontent:'Since 2009, all projects developed by SOHO China have been designed and built in compliance with the US Green Building LEED standard. To date, 8 projects have obtained LEED Gold Certification, and 1 project has obtained LEED Silver Certification. The LEED certified area has reached 2.18 million m², accounting for 44% of the total development area of SOHO China.',
        devnum:'2.18',
        devnumdetail:'Million m²',
        devnumcontent:'LEED certified area',
        devnum1:'44%',
        devnumcontent1:'Of total development area',

        //绿色运营
        opentitle:'GREEN OPERATION',
        opencontent:'In 2013, SOHO China established an energy-saving center. In the same year, the Company built an energy management platform to achieve green operation and reduce energy consumption through energy monitoring and analysis, energy-saving transformation and a series of management systems.',
        opendetail:'All SOHO China projects have achieved',
        opendetail1:'Platform Management',

        //绿色荣耀
        honourtitle:'GREEN HONORS',
        honourhead:'SOHO China has repeatedly received government recognition and awards for energy conservation and emission reduction, with a total reward of',
        honourtext:'',
        honournum:'14,000,000',
        honourunit:'RMB',
        honourcontenttop1: 'In 2016, Wangjing SOHO and Guanghualu SOHO II obtained LEED Gold Certification, and received a prize of RMB4.52 million from the "Guiding Fund for Energy Conservation Development in Chaoyang District, Beijing".',

        honourcontentbottom1:'',
        honourcontenttop2:'In 2017, Guanghualu SOHO II ice-storage centralized air-conditioning and cooling station received RMB420,000 from the “Beijing City’s Comprehensive Pilot Work of Electricity Demand Side Management Prize”.',

        honourcontentbottom2:'',
        honourcontenttop3:'In 2018, SOHO China’s Energy Management Platform and LED lighting energy-saving renovation project received RMB3.59 million from the "Guiding Fund for Energy-saving Development in Chaoyang District, Beijing."',
        honourcontentbottom3:'',
        honourcontenttop4:'In 2018, over 14,000 tons of carbon allowances were transferred for energy conservation and carbon emission reduction, of which the carbon trading profit amounted to RMB550,000.',
        honourcontentbottom4:'。',
        honourcontenttop5:'In 2020, Tower A and B of Jianwai and SOHO Nexus Center received a bonus of RMB4.93 million from the "Beijing Public Building Energy-saving and Green Transformation Award Fund".',

        honourcontentbottom5:'',
        honourcontenttop6:'In 2021, Galaxy SOHO, Wangjing SOHO Tower 3, Guanghualu SOHO II and SOHO Tianshan Plaza received "Certificate fo Green Building Lable (China Two-Star)".',

    },
    foundation:{
        title:'SOHO China has believed in giving back to the society, and has consistently contributed to poverty alleviation, disaster relief, and education with its corporate strengths. In 2005, SOHO China Foundation was established.',
        nav1:'Yang Zheng Kindergarten',
        nav2:'Anti-epidemic Work and Disaster Relief',
        nav3:'Education aid in Western China',
        nav4:' SOHO China Scholarships',
        nav5:'Environmental Protection',
        nav6:'We are Family Restaurant',
        nav7:'Special Olympics Unified School',
        nav8:"Virtue Education",
        nav9:"Zero Carbon Library",
        carbonnum:'20',
        carbontitle:'ZERO CARBON LIBRARY',
        carboncontent:'Supporting "rural revitalization" and enrich the spiritual and cultural life of rural residents, SOHO China Foundation has invested RMB20 million to build a "zero-carbon library" in Shizui Village, Maiji District, Tianshui in 2021, providing a cultural and leisure location for residents in the nearby neighborhoods. In the meantime, it serves teachers and students in local kindergartens, primary and secondary schools, and colleges and universities. The library will be completed by the end of December 2022.',
        carbondtag:'Donated to Build A Public Library',
        kindergartentitle:'YANG ZHENG KINDERGARTEN',
        kindergartencontent:'Located in Shizui Village, Mapaoquan Town, Maiji District, Tianshui City, Gansu Province, Yang Zheng Kindergarten was established as a non-profit program with a donation of RMB30 million. It was completed in January and opened in October, 2020. The Family Leaning House Education Group currently manages Yang Zheng Kindergarten, of which the capacity reaches 180 children. An annual investment of RMB3 million will be invested.',
        kindergartennum:'30',
        kindrengartenunit:'Million',
        kindergartendetail:'Donated to Non-profit Kindergarten',
        disastertitle:'ANTI-EPIDEMIC WORK AND DISASTER RELIEF',
        disasternum:'166',
        disasterunit:'Million RMB',
        disasterdetail:'Epidemic Subsidy',
        disastercontent:'In 2020, SOHO China provided tenants with subsidies to overcome the difficulties.',
        disasternum1:'200',
        disasterunit1:'Million RMB',
        disasterdetail1:'Tianshui Huaniu Apples',
        disastercontent1:'Tianshui Huaniu apples given to seven hospitals in Wuhan via Red Cross Society of China.',
        disasternum2:'120',
        disasterunit2:'Million RMB',
        disasterdetail2:'Nucleic Acid Test Kits',
        disastercontent2:'Given to the Huangshi City Center for Disease Control and Prevention in Hubei Province; and medical equipment including N95 masks and negative pressure masks given to the Wuhan Assistance Team of Peking Union Medical College Hospital.',
        disastercontent22:'',
        //西部助学
        westtitle:'EDUCATION AID IN WESTERN CHINA',
        westnum:'40',
        westunit:'Million RMB',
        westcontentp:'Over the years, approximately RMB40 million has been donated to Panjizhai School in Maiji District, Tianshui, Gansu, for the construction of teaching buildings, playgrounds, procurement of school uniforms and teaching aids, and support for teacher training projects.',
        westcontentbottom:'',
        westnumright:'10',
        westunitright:'Million RMB',
        westcontentright:'In 2007, RMB10 million was donated to improve the educational development of Qinghai Huangnan Tibetan Autonomous Prefecture,the largest donation to Qinghai at that time.',


        //soho中国助学金
        studenttitle:'SOHO CHINA SCHOLARSHIPS',
        studentcontent:'In 2014, SOHO China Scholarships was launched, connecting China\'s undergraduates with financial need to the best education the world has to offer. By 2020, a total of 222 undergraduate Chinese students had benefited from this initiative.',
        studentnum:'222',
        studentunit:'',
        studentDetail:'Chinese Undergratuates Benefitted',

        //能源突破
        enerytitle:'BREAKTHROUGH ENERGY COALITION',
        enerycontent:'In 2016, SOHO China joined Breakthrough Energy Coalition established by Bill Gates and business leaders from 28 top internet companies and investment groups. Breakthrough Energy commits to improving the production and storage of electric energy that will lead the world to net-zero emissions.',
        //一家人
        familytilte:'WE ARE FAMILY RESTAURANT',
        familycontent:'“We are Family” restaurant aims to provide free lunch for the elderly over 80 in Shizui Village, Mapaoquan, Maiji, Tianshui, Gansu. The restaurant officially opened in November 2020, and a total of 22 elders had benefited by the end of 2020.',

        //学校
        scholltitle:'SPECIAL OLYMPICS UNIFIED SCHOOL',
        schollcontent:'In cooperation with the Shanghai Special Care Foundation to develop public welfare projects , aiming to serve the disabled and improve their physical, mental and spiritual conditions. By the end of 2020, a total of RMB6.5 million had been donated.',

        //美德培训
        virtuetitle:' CHILDREN’S VIRTUE EDUCATION',
        content1:'Between 2008 and 2012, this project trained over 1,700 teachers and 250 principals in Tianshui, providing virtue courses for 61,000 children. In response to the outdated toilets in Tianshui, 45 toilets were donated with 35,000 children benefited.',
        content2:'The Children\'s Virtues Project was designed to awaken a sense of personal and communal responsibility among children and spark their drive for excellence. Children from kindergarten to Grade 3 engaged in character-building exercises which highlighted different virtues, such as Courtesy, Responsibility, Truthfulness, and Helpfulness. The Children\'s Virtues program was specially developed to target early education and considered how children of different ages process and learn with a curriculum comprised of songs, games and interactive activities to teach virtues.',
        virtuenum:'61K',
        virtuedetail:'Children Benefited',
        envtitle:'ENVIRONMENTAL PROTECTION',
        envcontent:'In order to improve the sanitation conditions and the living environment in rural areas, SOHO China Foundation established a special team in December 2021 to help the environmental protection of Shizui Village, Maiji District, Tianshui, with a total investment of over RMB400,000. The team donated a sanitation vehicle to the villagers for construction and renovation of 8 garbage sites, installed 54 waste classification bins, and promoted the concept of waste classification to the villagers.',

                //百草园
                herbaltitle:'Bai Cao Garden',
                herbalcontent:'In 2022, the SOHO China Foundation invested RMB4 million yuan and initiated a public welfare project named Bai Cao Garden in Shizui Village, Mapaoquan Town, Maiji District, Tianshui City, Gansu Province. The project aims to provide a location to study nature and conduct research for the children and teachers at Yang Zheng Kindergarten. The smart planting shed in Bai Cao Garden adopts high-tech soilless planting technology, which can grow various vegetables, fruits and other plants indoors without soil. The system automatically waters, fertilizes, and monitors indoor temperature, humidity and light conditions. The automatic fill light and sun curtain ensures the plants acquire enough sunlight and required temperature and humidity. In this way, the plants can grow all year round. The scientific and technological planting at Bai Cao Garden allows children to experience the joy of harvesting all year round, and have them embark on the exploration of modern agricultural development.                '
    },
    clients:{
        bannertext:'FREE ACCESS TO 5G LAB',
        bannertext1:'Integrating 8 categories of 5G innovative application scenarios',
        bannertext2:' Visitors as of March 2021',
        visitnum:'4755',
        visitunit:'People',
        visitnum1:'316',
        visitunit1:'Companies',

        //服务
        servicetitle:'CONSISTENT IMPROVEMENT OF PROPERTY MANAGEMENT SERVICES',
        servicetitle1:'Establishing A Comprehensive And Fast-Response Property Service System',

        //第一个li
        serviceLititle:'Setting up customer service work orders',
        serviceLinum:'15',
        servicetop:'Minutes',
        servicebottom:'Guaranteed Arrival',
        serviceLinum1:'1',
        servicetop1:'Working Day ',
        servicebottom1:'Task Completion',
        //第二个li
        serviceLititle2:'Intelligent property cleaning',
        serviceLinum2:'42',
        servicetop2:'Intelligent Cleaning Robots Working Around The Clock',
        servicebottom2:'',
        //第三个li
        servicecontent:'Introducing three majortelecom operators and secondary operators, ranking mobile and network signals monthly to ensure high-speed Internet signals in buildings.',

        //第四个li
        serviecontent1: 'Establishing an outsourced service inspection system and a property quality inspection system.',
        serviecontent2: '',

        //体系
        systemtitle:'BUILDING A CUSTOMER INFORMATION SECURITY SYSTEM',
        systemcontent:'SOHO China attaches importance to information and network security, continuously improves the management system, and strives to maintain customer personal information and privacy. At present, an information security system integrating security technology, security management and security verification has been established.',

        //意见
        handlertitle:'CUSTOMER SATISFACTION SURVEY AND COMPLAINT HANDLING',
        handlercontent:'Establishing a call center to quickly, directly and independently collect inquiries, calls for repairs, suggestions and complaints; handling problems through multiple channels in a timely manner; carrying out satisfaction surveys. In 2023, tenant satisfaction score was 99.81. A third-party company was hired to conduct surprise inspections in the form of mystery visits. In 2023, the satisfaction of mystery visitors reached 96.27 points. The annual Net Promoter Score (NPS) survey was carried out. In 2023, the NPS was 79.16 points.',
        handlerdetail:'2023 Tenant Satisfaction Score'

    },
    hr:{
        hrtitle:'COMPLIANCE, EQUALITY AND DIVERSITY IN EMPLOYMENT',
        hrtitle1:'& INTERESTS PROTECTION',
        hrcontent:'SOHO China adheres to the principle of lawful, equal and diversified employment, refines HR management system, values the democratic management of employees, and effectively improves the rights and interests of employees.',
        hrcontact:'Labor Contracts Signing Rate',
        hryear:' Employee Number',
        people:'',
        age:'30 and under',
        age2:'30-39',
        age3:'40 and above',
        //平台
        platform:'EMPLOYEE TRAINING AND DEVELOPMENT',
        platformcontent:'By creating an online knowledge management platform, we combine online and offline training to build a "two-pillar" talent training methodology of vocational training course and internal trainer systems.',
        platformtitle:'',
        platformtopunit:'Sessions',
        platformtopdetail:'Special training completed in 2023',
        platformcenterunit:'Hours',
        platfromcenterdetail:'Training time',
        platformbottomunit:'',
        platformbottomdetail:'Participants',

        //banenr
        hrbannercontent:'SOHO China regularly organizes employee sports games, birthday parties, family day activities to create anhonest, united, and innovative" working atmosphere and corporate culture.',
        hrbannercontent2:'',
        degreetitle:'IMPROVING EMPLOYEE SATISFACTION',
        degreecontent:'The company conducted employee satisfaction survey in 2023, which included Net Promoter Scores (NPS), Occupational health and safety, Business ethics, etc.  The survey covers multi-dimesional detailed employee satisfaction focuses. ',
        degreecontent1:'1,708 questionairs were sent to the employees and 1,622 valid feedback were collected.  The employee participation rate was 95%.  The overal employee satisfaction score was 4.6, which remains relatively high level and is the same as the score of 2022.',
        degreecontentright:'Employee Satisfaction (out of 5 points)',
        degreecontentrighttext:'score',
        noticeHelth:'HEALTH AND SAFETY',
        noticeContent:'We put the occupational health and safety of our employees first. We believe that safety comes from prevention and accidents come from paralysis. In 2023, there was no safety accidents with deaths on duty.'
    },
    control:{
        controltitle:'ESG GOVERNANCE',
        controltitle1:'ESG GOVERNANCE STRUCTURE',
        esgpolicy:'ESG POLICY',
        connecttitle:'STAKEHOLDER COMMUNICATION',
        connectcontent1:'Valuing stakeholder communication, building diversified communication channels.',
        connectcontent2:'Consistently improving information disclosure and listening to feedbacks from stakeholders.',
        assessment:'MAJOR TOPIC EVALUATION',
        assessmentcontent:'Via regular questionnaires, the views and expectations of stakeholders on the company\'s ESG can be understood, with the assured covering of information disclosure of key issues. In 2020, a total of 626 questionnaires were collected, and a matrix of key issues were drawn up based on the questionnaires to provide basis and guidance for future sustainable development management.',
        corruption:'ANTI-CORRUPTION',
        corruptioncontent:'The Company takes "Honesty, Unity and Innovation" as the corporate culture, attaching importance to integrity management, and places honesty as the prerequisite for development. "SOHO China Code of Professional Ethics" has been enacted to regularly conduct trainings, enhance employees\' awareness of performing duties, and prevent fraud and corruption.',
        risk:'ESG Risk Management'
    },

    media:{
        videotitle:'VIDEO NEWS',
        newstitle:'MEDIA NEWS',
    },
    activity: {
        title:"COMMUNITY ACTIVITIES",
        // title:"社会活动"
    },
}
