import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
Vue.use(Vuex)

let defaultLanguage = Cookie.get('language') || 'zh'

export default new Vuex.Store({
    state:{
        lang:defaultLanguage
    },
    actions:{

    },
    mutations:{
        changeLanguage(state,payload){
            state.lang = payload
        }
    }
})
