import axios  from "axios";


const service = axios.create({
// 当执行 npm run dev  => .evn.development => /api => 跨域代理
    baseURL: process.env.VUE_APP_BASE_API, // npm  run dev  => /api npm run build =>  /prod-api
    timeout: 5000 // 设置超时时间
})
// 请求拦截器
service.interceptors.request.use(config => {

    return config // 必须要返回的
}, error => {
    return Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(response => {
    // axios默认加了一层data
    const data = response.data
    //   要根据success的成功与否决定下面的操作
    // if (success) {
    //     return data
    // } else {
    //     // 业务已经错误了 还能进then ? 不能 ！ 应该进catch
    //     Message.error(message) // 提示错误消息
    //     return Promise.reject(new Error(message))
    // }

    return data
}, error => {

    return Promise.reject(error)
})

export default service
