import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookie from 'js-cookie'
import Zh from './zh'
import En from './en'
Vue.use(VueI18n)
export default  new VueI18n({
    locale:Cookie.get('language') || 'zh' ,     //zh 中文   en 英文
    messages:{
        zh:{
            ...Zh
        },
        en:{
            ...En
        }
    }
})
