<template>
  <div>
    <nav-header></nav-header>
    <div class="index-container">
      <div :class="lang">
        <div  class="banner pubCon" v-if="this.$i18n.locale =='zh'">
          <swiper v-bind:options="swiperOption" class="pubCon">
            <swiper-slide v-for="(item,index) in slideList" v-bind:key="index">
              <img v-bind:src="item.img.url">
            </swiper-slide>
          </swiper>
        </div>
        <div  class="banner pubCon" v-if="this.$i18n.locale=='en'">
          <swiper v-bind:options="swiperOption" class="pubCon">
            <swiper-slide v-for="(item,index) in enslideList" v-bind:key="index">
              <img v-bind:src="item.img.url">
            </swiper-slide>
          </swiper>
        </div>
        <div class="container-padding">
          <!--三级导航-->
          <div class="three-nav pubCon pc-nav">
            <ul>
              <li>
                <div class="three-nav-container">
                  <span >
                  <router-link :to="{path:'/env'}" >{{$t('route.env')}}</router-link>
                  </span>
                </div>
              </li>
              <li class="pc-li">
                <div class="three-nav-container"
                     @mouseenter="mouseenter()"
                     @mouseleave="onmouseleave()"
                >
                  <span :class="{hoverList:hover}">{{$t('route.socity')}}</span>
                  <div class="three-nav-detail" ref="threecontent">
                    <router-link  :to="{path:'/activity'}">{{$t('route.activity')}}</router-link>
                    <router-link  :to="{path:'/charity'}">{{$t('route.Charity')}}</router-link>
                    <router-link :to="{path:'/employee'}">{{$t('route.employee')}}</router-link>
                    <router-link  :to="{path:'/client'}" >{{$t('route.Client')}}</router-link>
                    <a href="https://ppp.sohochina.com/">{{$t('route.development')}}</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="three-nav-container"    
                     @mouseenter="controlMouseenter()"
                     @mouseleave="controlOnmouseleave()">
                <span :class="{hoverList:controlFlag}">
                   <router-link  to="" >{{$t('route.gov')}}</router-link>
                </span>
                  <div class="three-nav-detail" ref="controlThreecontent">
                      <router-link  :to="{path:'/governancePolicy'}">{{$t('route.policy')}}</router-link>
                      <router-link  :to="{path:'/governanceRisk'}">{{$t('route.risk')}}</router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="three-nav-container">
                  <span>
                     <router-link  :to="{path:'/media'}" >{{$t('route.media')}}</router-link>
                  </span>
                </div>
              </li>
              <li>
                <div class="three-nav-container"    
                     
                     @mouseenter="reportMouseenter()"
                     @mouseleave="reportOnmouseleave()">
                <span :class="{reportHover:reportFlag}">
                   <router-link  to="" >{{$t('route.menureport')}}</router-link>
                </span>
                  <div class="three-nav-detail" ref="reportThreecontent">
                      <router-link  :to="{path:'/esgReort'}" style="letter-spacing: 1px;">{{$t('route.esgCert')}}</router-link>
                      <router-link  :to="{path:'/tcfdReort'}" style="letter-spacing: 1px;">{{$t('route.TCFD')}}</router-link>
                    </div>
                </div>
              </li>
              <!-- <li>
                <div class="three-nav-container">
                <span style="letter-spacing: 1px">
                   <router-link  :to="{path:'/esgReort'}" style="letter-spacing: 1px">{{$t('route.esgCert')}}</router-link>
                </span>
                </div>
              </li> -->

            </ul>

            <div class="grate">
              <!-- <div class="grate-msci">
                <a href="https://esgicp.msci.com" target="_blank">
                  <div class="grate-msci-left">
                      <span class="grate-common">MSCI</span>
                      <span class="grate-text"  v-if="this.$i18n.locale=='zh'">评级</span>
                      <span class="grate-text" v-else> Rating</span>
                  </div>
                  <div class="grate-msci-img">
                    <img src="../assets/grate.png" alt="">
                  </div>
                </a>
              </div> -->
              <div class="grate-msci">
                <a href="https://www.gresb.com/nl-en/" target="_blank">
                  <div class="grate-msci-left">
                    <span class="grate-common">GRESB</span>
                    <span class="grate-text"  v-if="this.$i18n.locale=='zh'">评级</span>
                    <span class="grate-text" v-else> Rating</span>
                  </div>
                  <div class="grate-msci-right">
                    <img src="../assets/fivestarts.png" alt="">
                  </div>
                </a>
              </div>
            </div>
          </div>
          <!--     移动端二级导航-->
          <div class="three-nav pubCon mobile-li">
            <ul>
              <li>
                <div class="three-nav-container">
                  <span >

                    <router-link :to="{path:'/env'}" >
                     <em class="iconfont icon-environment"></em>
                       {{$t('route.env')}}
                    </router-link>
                  </span>
                </div>
              </li>
              <li >
                <div class="three-nav-container">
                    <span class="nav-span" @click.stop="actionClick" >
                    <em class="iconfont icon-shehui"></em>
                      {{$t('route.socity')}}</span>
                  <action-sheet v-model="show" :actions="actions" @select="onSelect" />
                </div>
              </li>
              <li>
                <div class="three-nav-container">
                  <span  class="nav-span" @click.stop="controlActionClick">
                     <div class="nav-span">
                        <em class="iconfont icon-jiagou"></em>
                       {{$t('route.gov')}}
                      
                      </div>
                  </span>
                  <action-sheet v-model="controlShow" :actions="controlActions" @select="controlOnSelect" />
                </div>
              </li>
              <li>
                <div class="three-nav-container">
                <span>
                   <router-link  :to="{path:'/media'}" >
                      <em class="iconfont icon-duomeiti"></em>
                     {{$t('route.media')}}</router-link>
                </span>
                </div>
              </li>
              <li>
                <div class="three-nav-container" id="moble-report">
                  <span  @click.stop="reportActionClick">
                     <div class="nav-span">
                        <em class="iconfont icon-zhengshu-copy"></em>
                         {{$t('route.menureport')}}
                      </div>
                  </span>
                  <action-sheet v-model="reportShow" :actions="reportActions" @select="reportOnSelect" />
                </div>
              </li>
              <!-- <li>
                <div class="three-nav-container">
                <span>
                   <router-link  :to="{path:'/esgReort'}" style="letter-spacing: 1px">
                      <em class="iconfont icon-zhengshu-copy"></em>
                     {{$t('route.cert')}}
                   </router-link>
                </span>
                </div>
              </li> -->
            </ul>
          </div>
          <!--环境-->
          <div class="env pubCon" @click="envclick">
            <ul class="env-left clearfix ">
              <li v-for="(item,index) in circleList" :key="index">
                <van-circle v-model="item.num"
                            :rate="item.rate"
                            :speed="20"
                            :size="size"
                            :stroke-width="120"
                            color="#b9dcd2"
                            :text="text(item.num)"
                            stroke-linecap="butt"
                />
                <span style="display: block">{{$t('route.'+item.text)}}
                </span>

              </li>
            </ul>
            <div class="env-right feature">
              <h3>{{$t('route.envtitle')}}</h3>
              <p>{{$t('route.envcontent')}}</p>
            </div>
          </div>
          <!--pc社会-->
          <div class="society-pc" @click="societyClick">
            <div class="society pubCon ">
              <div class="society-left feature">
                <h3>{{$t('route.socitytitle')}}</h3>
                <p>{{$t('route.societycontent')}}</p>
              </div>
              <div class="society-right feature">
                <div class="society-right-top">
                  <h4>{{$t('route.tstitle')}}</h4>
                  <countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' class="num"></countTo>

                  <span class="Emoney">{{$t('route.unit')}}</span>

                </div>
                <div class="society-right-top society-right-bottom">
                  <h4>{{$t('route.Kindergarten')}}</h4>
                  <countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' class="num"></countTo>
                  <span class="Emoney">{{$t('route.unit')}}</span>
                </div>
              </div>
            </div>
          </div>

          <!--移动端社会-->
          <div class="society-mobile">
            <div class="society pubCon "  @click="actionClick" >
              <div class="society-left feature">
                <h3>{{$t('route.socitytitle')}}</h3>
                <p>{{$t('route.societycontent')}}</p>
              </div>
              <div class="society-right feature">
                <div class="society-right-top ">
                  <h4>{{$t('route.tstitle')}}</h4>
                  <countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' class="num"></countTo>

                  <span class="Emoney">{{$t('route.unit')}}</span>

                </div>
                <div class="society-right-top society-right-bottom">
                  <h4>{{$t('route.Kindergarten')}}</h4>
                  <countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' class="num"></countTo>

                  <span class="Emoney">{{$t('route.unit')}}</span>

                </div>


              </div>
            </div>
          </div>

          <!--管治-->
          <div class="control pubCon" @click="controlClick">
            <div class="control-left feature" v-if="this.$i18n.locale=='zh'">
              <img src="../assets/homecontrol-zh.png" alt="">
            </div>
            <div class="control-left feature" v-if="this.$i18n.locale=='en'">
              <img src="../assets/homecontrol-en.png" alt="">
            </div>
            <div class="control-right feature">
              <h3>{{$t('route.govtitle')}}</h3>
              <p>{{$t('route.govcontent')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/Header"
import { Toast,ActionSheet} from 'vant';
import countTo from 'vue-count-to';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {mapState} from 'vuex'
import { getdataInfo } from "../api/home"
export default {
  name: "Index",
  data(){
    return{
      id:1 ,
      pageType:'home',
      show: false,
      controlShow:false,
      reportShow:false,
      chinainest:'公益',
      actions: [{ name: "公益慈善" ,id:"1"}, { name: this.$i18n.t('route.employee'),id:2}, { name: this.$i18n.t('route.Client'),id:3 }],
      controlActions:[],
      reportActions:[],
      size:180,
      hover:false,
      govHover:false,
      controlFlag:false,
      reportFlag:false,
      height:0,
      currentRate:0,
      startVal: 100000,
      endVal: 166000000,
      startVal1: 1000000,
      endVal1: 10000000,
      swiperOption:{
        loop: true, // 循环模式选项
        autoplay:true,
        effect : 'fade',
        delay: 1000,
        // cubeEffect: {
        //   shadowOffset: 100,
        //   shadowScale: 0.6
        // },
        fadeEffect: {
          crossFade: true,
        },

      },
      circleList:[
        {
          num:0,
          rate:68.1,
          text:"elecsave"
        },
        {
          num:0,
          rate:68.2,
          text:"consumption"
        },
        {
          num:0,
          rate:93.5,
          text:"watter"
        }
      ],
      slideList:[
        {
          id:"1",
          img:{
            url:"/img/banner1.jpg"
          }
        },
        {
          id:"2",
          img:{
            url:"/img/banner3.jpg"
          }
        },
        {
          id:"3",
          img:{
            url:"/img/banner1.jpg"
          }
        },
        {
          id:"4",
          img:{
            url:"/img/banner1.jpg"
          }
        }
      ],
      enslideList:[
        {
          id:"1",
          img:{
            url:"/img/enbanner1.jpg"
          }
        },
        {
          id:"2",
          img:{
            url:"/img/enbanner3.jpg"
          }
        },
        {
          id:"3",
          img:{
            url:"/img/enbanner1.jpg"
          }
        },
        {
          id:"4",
          img:{
            url:"/img/enbanner1.jpg"
          }
        }
      ] ,
      homedatanum:300000000

    }
  },

  components:{
    Swiper,
    SwiperSlide,
    [Toast.name]: Toast,
    ActionSheet,
    countTo,
    NavHeader
  },
  computed: {
    ...mapState({
      lang:state => state.lang
    })
  },
  created() {
    var url = window.location.hash;
    if(url.substring(url.length-2)=='en'){
      this.$i18n.locale = 'en'
      this.$store.commit('changeLanguage','en')
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });


    this.$scroll().reveal(".society-right", {

      beforeReveal: () => {
        this.test()
      },
    });

    if(this.isPhone()){
      this.size =100
    }

    /*获取数据*/
    this.getdata()
  },
  methods:{
    envclick() {
      this.$router.push("/env")
    },
    societyClick() {
      this.$router.push("/charity")
    },

    controlClick() {
      this.$router.push("/governancePolicy")
    },
    actionClick(){
      this.show = true
      let actionsList = [{ name:this.$i18n.t('route.activity'),id:"4"},{ name:this.$i18n.t('route.Charity'),id:"1"}, { name: this.$i18n.t('route.employee'),id:2}, { name: this.$i18n.t('route.Client'),id:3 },{ name:this.$i18n.t('route.development'),id:"5"},]
      this.actions =actionsList
    },
    controlActionClick(){
      this.controlShow = true
      let actionsList = [{ name:this.$i18n.t('route.policy'),id:"1"}, { name: this.$i18n.t('route.risk'),id:2}]
      this.controlActions = actionsList
    },
    reportActionClick(){
      this.reportShow = true
      let actionsList = [{ name:this.$i18n.t('route.esgCert'),id:"1"}, { name: this.$i18n.t('route.TCFD'),id:2}]
      this.reportActions = actionsList
    },
    test(){
      this.endVal = 166000000
      this.endVal1 = 10000000
    },
    text(currentRate) {
      return (100-(currentRate.toFixed(1))).toFixed(1) + '%';
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起

      this.show = false;
      if(item.id ==4){
        this.$router.push("/activity")
      }
      if(item.id==1){
        this.$router.push("/charity")
        return
      }

      if(item.id == 2){
        this.$router.push("/employee")
        return;
      }

      if(item.id == 3){
        this.$router.push("/client")
      }

      if(item.id == 5){
        window.location.href='https://ppp.sohochina.com/';
      }
    },
    controlOnSelect(item){
      this.controlShow = false;
      if(item.id ==1){
        this.$router.push("/governancePolicy")
      }
      if(item.id==2){
        this.$router.push("/governanceRisk")
        return
      }
    },
    reportOnSelect(item){
      this.reportShow = false;
      if(item.id ==1){
        this.$router.push("/esgReort")
      }
      if(item.id==2){
        this.$router.push("/tcfdReort")
        return
      }
    },
    /*三级导航*/
    mouseenter(){
      this.hover=true
      if(this.isPhone()){
        this.$refs.threecontent.style.height = 100+"px"
      }else {
        this.$refs.threecontent.style.height = 253+"px"
      }

    },
    onmouseleave(){
      this.$refs.threecontent.style.height= 0+"px"
      this.hover = false
    },
    controlMouseenter(){
       this.controlFlag=true
      this.$refs.controlThreecontent.style.height = 100+"px"
    },

    controlOnmouseleave(){
      this.$refs.controlThreecontent.style.height= 0+"px"
       this.controlFlag = false
    },
    reportMouseenter(){
       this.reportFlag=true
      this.$refs.reportThreecontent.style.height = 100+"px"
    },
    reportOnmouseleave(){
      this.$refs.reportThreecontent.style.height= 0+"px"
       this.reportFlag = false
    },

    govmouseenter(){
      this.govHover=true
      if(this.isPhone()){
        this.$refs.govthreecontent.style.height = 100+"px"
      }else {
        this.$refs.govthreecontent.style.height = 253+"px"
      }

    },


    isPhone() {
      let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return mobile!= null
    },
    //调转

    //数据获取

    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const homedata= JSON.parse(data.esgContent.cnContent)
      const en_homedata= JSON.parse(data.esgContent.enContent)
      // this.en_homedata = JSON.parse(data.esgContent.cnContent)
     // this.homedatanum= Number(homedata.childMoney)
      let slideList = homedata.bannerList
      this.slideList = slideList.sort(function (a,b){
        return a.sort-b.sort
      })
      let enslideList = en_homedata.enbannerList
      this.enslideList = enslideList.sort(function (a,b){
        return a.sort-b.sort
      })
      const power = 100-Number(homedata.power)
      const gas = 100-Number(homedata.gas)
      const water = 100-Number(homedata.water)
      this.circleList[0].rate =power
      this.circleList[1].rate =gas
      this.circleList[2].rate =water
      // if(this.$i18n.locale=='zh'){
      //   console.log('zhzhzhhz')
      // }else {
      //   console.log('test')
      // }
    }
  }
}
</script>

<style lang="scss">
.van-circle__text{
  color: #24c3ad !important;
  font-size: 36px;
  font-family:BarlowCondensed-Regular;
  font-weight: bold;

}
.sub{
  font-size: 12px;

}
.index-container{

  .pubCon {
    width: 1200px;
    margin: 0 auto;
  }
  .society-mobile{
    display: none;
  }
  .mobile-li{
    display: none;
  }
  .zh .three-nav-container span{
    letter-spacing: 5px;
  }
  .zh .three-nav-container span a{
    letter-spacing: 5px;
  }
  .zh .three-nav-detail a{
    letter-spacing: 3px;
  }

   h3 {
    padding:0px 0 20px;
    color:#22c3ad;
    font-weight: bold;
  }
  .zh h3{
    font-size: 62px;
    letter-spacing: 20px;
  }
  .en h3{
    font-size: 52px;
    letter-spacing:0px;
    font-family: "BarlowCondensed-bold";
    font-weight: 800;
    color: rgb(1, 167, 158);
  }

  .zh p{
    letter-spacing: 2px;
  }

  p{
    text-align: justify;
    font-size: 16px;
    color: #666860;
    line-height:30px;
  }
  .en p{
    font-family: "Barlow-Regular";
    color: #000;
    text-align: justify;
  }
  .zh .society-right{
    margin-top: 115px;
  }
  .en .society-right{
    padding-left: 26px;
    margin-top: 103px;
  }

  /*三级导航*/
  .three-nav{
    width: 950px;
    height: 40px;
    position: relative;
  }
  .three-nav>ul{
    width: 100%;
    margin: 20px auto 0;
    height: 100%;

  }
  .three-nav>ul>li{
    float: left;
    height:40px;
    line-height: 40px;
    margin-left: 5px;
    width:180px;
    text-align: center;
  }

  .three-nav-container{
    background: #ffffff;
  }
  .three-nav-container span{
    display: block;
    height: 50px;
    line-height: 50px;
    color: #888888;
    font-size: 18px;

  }

  .en .three-nav-container span{
    font-family: "Barlow-Regular";
  }

  .three-nav-container span a{
    display: block;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    color: #888888;
  }
  .en .three-nav-container span a{
    font-family: "Barlow-Regular";
  }


  .three-nav-container span a:hover{
    color: #43b3a4;
    font-weight: bold;

  }

  .three-nav-detail a{
    color: #888888;
    font-size: 16px;
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #888888;

  }
  .en .three-nav-detail a{
    font-family: "Barlow-Regular";
  }

  .three-nav-detail a:hover{
    background: #b9dcd2;

  }

  .three-nav-container .hoverList{
    border-bottom: 3px solid #43b3a4;
    color: #43b3a4;
    font-weight: bold;

  }
  .three-nav-container .reportHover{
    border-bottom: 3px solid #43b3a4;
    color: #43b3a4;
    font-weight: bold;

  }
  .three-nav-detail{
    overflow: hidden;
    height: 0;
    transition: all 0.5s;

  }
 .en .grate a {
   span{
     font-family: "Barlow-Regular";
   }
   .grate-text{
     margin-left: 2px;
   }

  }
  .grate{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    right: -130px;
    top: 2px;
    width: 160px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    box-sizing: border-box;
    .grate-common{
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      letter-spacing: 0.01em;
      color: #2F2F2F;
    }
    .grate-text{
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: #454545;
      opacity: 0.57;
      margin-left: 5px;
    }
    .grate-msci a{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .grate-msci-img{
      img{
        width: 25px;
        height: 25px;
      }
    }
    .grate-msci-right{
      //width: 40px;
      //height: 40px;
      //line-height: 40px;
      //font-style: normal;
      //font-weight: 700;
      //font-size: 12px;
      //color: #FFFFFF;
      //border-radius: 50%;
      //background: #F3AD54;
      //text-align: center;
      margin-left: 2px;
      img{
        width: 45px;
        height: 12px;
      }
    }
  }
  .last-EsG{
    width:130px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    background-color:#22c3ad;
    border-radius: 5px;
  }

  .grate-img img{
    display: block;
    width: 150px;
    height: 56px;
  }


  .last-EsG a{
    display: block;
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;

  }
  .en  .last-EsG a{
    font-size: 12px;
    font-family: "Barlow-Regular";
  }


  .env{
    display: flex;
    margin-top:150px;
    box-sizing: border-box;
    padding-bottom: 160px;
    align-items: flex-end;
    cursor: pointer;
  }
  .env-left{
    width: 60%;
    z-index: -9999;
  }
  .env-left li{
    float: left;
    width: 33%;
    text-align: center;
  }
  .env-left li span{
    display: inline-block;
    padding-top: 3px;
    font-size: 10px;
    color:#666860;
  }
  .en .env-left li span{
    font-family: "Barlow-Regular";
    font-size: 8px;
  }
  .env-right{
    width: 40%;
    padding-top: 20px;
    box-sizing: border-box;
    padding-left: 70px;

  }


  .env{
    display: flex;
    margin-top:150px;
    box-sizing: border-box;
    padding-bottom: 160px;
  }
  .env-left{
    width: 60%;
  }
  .env-left li{
    float: left;
    width: 33%;
    text-align: center;
  }
  .env-left li span{
    display: inline-block;
    padding-top: 3px;
    font-size: 14px;
  }
  .env-right{
    width: 40%;
    padding-top: 20px;
    box-sizing: border-box;
    padding-left: 65px;

  }
  .society{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }
  .society-left{
    width: 60%;
    box-sizing: border-box;
    padding-left:60px;
  }

  .society-left p {
    padding-right: 65px;

  }


  .society-right{
    box-sizing: border-box;
    width: 40%;
    padding-left: 65px;
  }

  .zh .society-right h4{
    color: rgb(102,104,105);
    font-size: 24px;
    padding: 0px;
    font-weight: 500;
    margin-left: -15px;
  }
  .en .society-right h4 {
    font-size: 18px;
    font-weight: 100;
    font-family: "Barlow-Regular";
    margin-left: -15px;
  }
  .society-right span {
    color: rgb(206,180,150);
    font-size: 36px;
    font-family: "BarlowCondensed-bold";
    font-weight: 800;
    margin-left: -15px;
  }

    .society-right .num{
    font-size: 72px;
    letter-spacing: 1px;
  }
  .society-right-bottom{
    margin-top: 10px;
  }



  .control{
    display: flex;
    margin-top: 170px;
    padding-bottom: 100px;
    cursor: pointer;
  }
  .control-left{
    width: 60%;
    padding-left: 60px;

  }
  .control-left img{
    display: block;
    width: 597px;
    height: 306px;
    margin-top: 18px;
  }

  .control-right{
    box-sizing: border-box;
    width: 40%;
    padding-left:65px;
  }


}

@media(max-width:680px) {

  .index-container{
    .van-action-sheet__cancel, .van-action-sheet__item{
      padding: 20px 16px;
    }

    .container-padding{
      padding:0 15px;
    }
    .pubCon {
      width: 100%;
      margin: 0 auto;
    }
    .pc-nav{
      display: none;
    }
    .mobile-li{
      display: block;
    }
    .society-pc{
      display: none;
    }
    .society-mobile{
      display: block;
    }
    .zh #moble-report span{
      letter-spacing: 1px;
    }
    .zh .three-nav-container span{
      letter-spacing: 5px;
    }
    .zh .three-nav-container span a{
      letter-spacing: 5px;
    }
    .zh .three-nav-detail a{
      letter-spacing: 3px;
    }
    .zh .control-right{
      padding-top: 50px;
    }

     h3 {
      font-size: 40px;
      padding:0;
      color:#22c3ad;
      font-weight: bold;
    }

    .zh h3{
      font-size: 40px;
      letter-spacing: 10px;
    }
    .en h3{
      font-size: 18px;
      letter-spacing: 1px;
      text-align: left;
    }
    .zh p{
      letter-spacing: 1px;
    }

    p{
      text-align: justify;
      color: #666860;
      line-height:1.5;
    }
    .zh p{
      font-size: 14px;
    }
    .en p{
      font-size: 14px;
      text-align: left;
    }
    .zh .society-right{
      margin-top: 92px;
    }


    .van-circle__text{
      font-size: 16px;
    }


    .three-nav{
      width: 100%;
      height: 40px;
      position: relative;
    }

    .three-nav>ul{
      width: 100%;
      margin: 20px auto 0;
      height: 100%;

    }
    .three-nav>ul>li{
      float: left;
      height:30px;
      line-height: 30px;
      margin-left: 0px;
      width:20%;
      text-align: center;
    }

    .three-nav-container{
      background: #ffffff;
    }
    .zh .three-nav-container .nav-span{
      display: block;
      height: 30px;
      line-height: 30px;
      color: #888888;
      font-size: 16px;
      letter-spacing: 0px;
    }
    .en .three-nav-container .nav-span{
      display: block;
      height: 30px;
      line-height: 30px;
      color: #888888;
      font-size: 12px;
      letter-spacing: 0px;
    }

    .three-nav-container span a{
     height: 30px;
      line-height: 30px;
    }
    .three-nav-container span{

      height: 30px;
      line-height: 30px;


    }

    .zh .three-nav-container span a{
      display: block;
      font-size: 16px;
      letter-spacing: 0px;
      height: 30px;
      line-height: 30px;
      color: #888888;
    }
    .en .three-nav-container span a{
      display: block;
      font-size: 12px;
      letter-spacing: 0px;
      height: 30px;
      line-height: 30px;
      color: #888888;
    }
    .three-nav-container span a:hover{
      color: #43b3a4;
      font-weight: bold;

    }

    .three-nav-detail a{
      color: #888888;
      font-size: 12px;
      display: block;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #888888;
      letter-spacing: 1px;
    }
    .three-nav-detail a:hover{
      background: #b9dcd2;

    }

    .three-nav-container .hoverList{
      border-bottom: 3px solid #43b3a4;
      color: #43b3a4;
      font-weight: bold;

    }
    .three-nav-container .reportHover{
      border-bottom: 3px solid #43b3a4;
      color: #43b3a4;
      font-weight: bold;

    }
    .three-nav-detail{
      overflow: hidden;
      height: 0;
      transition: all 0.5s;

    }


    //.report{
    //  width: 20%;
    //  position: absolute;
    //  right:5px;
    //  top: 5px;
    //}
    //
    //
    //.report-img{
    //  width: 100%;
    //}
    //
    //.report-img img{
    //  display: block;
    //  width: 100%;
    //  height: 15px;
    //}

    .last-EsG{

      width:100%;
      height: 22px;
      line-height:22px;
      text-align: center;
      color: #ffffff;
      background-color:#22c3ad;
      border-radius: 3px;
    }
    .last-EsG a{
      display: block;
      width: 100%;
      height:auto;
      color: #ffffff;
      font-size: 10px;
      font-weight: bold;

    }

    .env{
      display: flex;
      flex-direction: column;
      margin-top:70px;
      box-sizing: border-box;
      padding-bottom:0px;

    }
    .env-left{
      width: 100%;
      z-index: -99;

    }
    .env-left li{
      float: left;
      width: 33%;
      text-align: center;
    }
    .env-left li span{
      display: inline-block;
      padding-top: 3px;
      font-size: 14px;
    }
    .env-right{
      width: 100%;
      padding-top: 50px;
      box-sizing: border-box;
      padding-left: 0px;
      display: flex;
      align-items: flex-start;

    }
    .env-right h3{
      width: 35%;

    }
    .env-right p {
      width: 65%;
      padding-right: 10px;
      color: #000;
    }


    .society{
      display: flex;
      flex-direction: column;
    }
    .society-left{
      width: 100%;
      box-sizing: border-box;
      padding-left:0px;
      padding-top: 50px;
      order: 2;
      display: flex;
      align-items: flex-start;
    }
    .society-left h3{
      width: 35%;
    }

    .society-left p {
      width: 65%;
      padding: 0px;
      padding-right:10px;


    }
    .en .society-right h4 {
      margin-left: 0;
      font-size: 12px;
    }

    .society-right{
      width:100%;
      padding-left:0px;
      margin-top: 0px;
      order: 1;
      text-align: left;

    }
    .en .society-right {
      padding-left:0px;
      margin-top: 65px;
    }

    .society-right h4{
      color: rgb(102,104,105);
      font-size: 22px;
      padding: 0px;
      font-weight: 500;

    }
    .zh .society-right h4{
      margin-left: 0;
    }
    .society-right span {
      color: rgb(206,180,150);
      font-size: 24px;
      font-family: "BarlowCondensed-bold";
      font-weight: 800;
      margin-left:0px;
    }
    .society-right .num{
      font-size: 40px;
    }
    .society-right-bottom{
      margin-top: 10px;
    }


    .control{
      display: flex;
      flex-direction: column;
      margin-top:90px;
      padding-bottom: 100px;
      text-align: center;
    }
    .control-left{
      width: 100%;
      padding-left:0px;

      order: 1;
    }
    .control-left img{
      display: block;
      width: 100%;
      height:auto;
      margin-top: 0;
    }

    .control-right{
      width:100%;
      order: 2;
      box-sizing: border-box;
      padding: 0px;
      display: flex;
      align-items: flex-start;
      margin-top: 0px;
      padding-top: 38px;
    }
    .control-right h3{
      width: 35%;
    }
    .control-right p{
      width: 65%;
      padding-right:10px;
    }



  }

}

</style>
