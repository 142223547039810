<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "babel-polyfill"

export default {
  name: 'App',
}
</script>

<style>
@import "./assets/reset.css";
@import "./assets/base.scss";
@import "./assets/icontfont/iconfont.css";
html,body{overflow:hidden;overflow-y:auto;}
</style>
