import Vue from "vue";
import Router from "vue-router";
import Home from "../pages/Home"
import Test from "../pages/test"
import Index from "../pages/Index"
Vue.use(Router)

  const router = new Router({
        routes:[
            {
                path:"/",
                name:"Home",
                component:Home,
                redirect:"/index",
                children:[
                    {
                        path: '/index',
                        name: 'index',
                        component: Index,
                    }, {
                        path: '/env',
                        name: 'env',
                        component: () => import('@/pages/Env.vue')
                    },
                    {
                        path:'/charity',
                        name:'charity',
                        component:() => import('@/pages/Foundation')
                    },
                    {
                        path:'/employee',
                        name:'employee',
                        component:() => import('@/pages/Hr')
                    },
                    {
                        path:'/client',
                        name:'client',
                        component:() => import('@/pages/Clients')
                    },
                    {
                        path:'/governance',
                        name:'governance',
                        component:() => import('@/pages/Control')
                    },
                    {
                        path:'/governancePolicy',
                        name:'governancePolicy',
                        component:() => import('@/pages/policy')
                    },
                    {
                        path:'/governanceRisk',
                        name:'governanceRisk',
                        component:() => import('@/pages/riskManagement')
                    },
                    {
                        path:'/media',
                        name:'media',
                        component:() => import('@/pages/Media')
                    },
                    {
                        path:'/detail',
                        name:'detail',
                        component:() => import('@/pages/Detail')
                    },
                    {
                        path:'/activity',
                        name:'activity',
                        component:() => import('@/pages/activity')
                    },
                    {
                        path:'/development',
                        name:'development',
                        component:() => import('@/pages/development')
                    },
                    {
                        path:'/activityDetail',
                        name:'activityDetail',
                        component:() => import('@/pages/activityDetail')
                    },
                    {
                        path:'/esgReort',
                        name:'esgReort',
                        component:() => import('@/pages/esgreport')
                    },
                    {
                        path:'/tcfdReort',
                        name:'tcfdReort',
                        component:() => import('@/pages/TCFDReport')
                    }
                ]
            },
            {
                path:"/test",
                name:"Test",
                component:Test,
            },

        ],
        scrollBehavior: () => {
            return{x:0, y:0}
            // if (savedPosition) {
            //     return savedPosition
            // } else {
            //     console.log(12399999)
            //     return { x: 0, y: 0 }
            // }
        },

    })

export default router


