import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import router from './router/index'
import { Circle, ActionSheet,Dialog,Icon } from 'vant';
import ScrollReveal from "scrollreveal";
import VueLazyLoad from 'vue-lazyload'
import i18n from "./lang/index"
import store from './store'
Vue.use(Circle,ActionSheet,i18n,Dialog,Icon);

Vue.use(VueLazyLoad,{
  loading:'/imgs/loading-svg/loading-bars.svg'
})
Vue.prototype.$scroll = ScrollReveal
Vue.config.productionTip = false

router.afterEach(() => {
  //console.log(to,from,next)
  setTimeout(()=>{
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?835964bea696b6d8477fbb9b263ba86c";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },0);
});
new Vue({
  render: h => h(App),
  router,
  i18n,
  store,
}).$mount('#app')
